<template>
  <div>
    <page-header :title="!$route.params.id ? 'Novo formulário' : 'Editando formulário'"></page-header>
    
    <v-tabs background-color="primary" dark  v-model="tab" style="border-radius: 18px 18px 0px 0px" grow icons-and-text>
      <v-tab fixedTabs class="d-flex flex-column"><div><v-icon>mdi-text</v-icon></div><div>Cabeçalho do formulário</div></v-tab>
      <v-tab :disabled="!form.id" class="d-flex flex-column"><div><v-icon>mdi-text-long</v-icon></div><div>Campos</div></v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" style="border-radius: 0px 0px 18px 18px">

      <v-tab-item outlined>
        <header-form @formCreated="handleFormCreated" @setFormId="handleSetFormId"/>
      </v-tab-item>

      <v-tab-item class="mb-2">
        <fields-form v-if="tab == 1" :form_id_aux="form_id"/>
      </v-tab-item>
    </v-tabs-items>

  </div>
</template>

<script>

export default {
  name: 'RegisterForm',
  components: {
    FieldsForm: () => import ('./Forms/FieldsForm.vue'),
    HeaderForm: () => import ('./Forms/HeaderForm.vue')
  },
  data: () => ({
    form: {
      id: null,
      items_attributes: []
    },

    tab: null,
  }),
  methods: {    
    handleFormCreated(e){
      this.form.id = e.id
      this.tab = e.tab
    },
    handleSetFormId(e){
      this.form.id = e.id
    },
  },
  computed: {
    form_id(){
      return this.form.id
    }
  }
}
</script>

<style scoped>

</style>